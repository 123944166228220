<script>
  import { onMount, tick } from "svelte";

  let contentElement;
  let lineNumbersElement;

  onMount(async () => {
    await tick(); // Wait for all pending updates to finish
    calculateLineNumbers();
  });

  function calculateLineNumbers() {
    if (!contentElement) return;

    const contentStyle = window.getComputedStyle(contentElement);
    let lineHeight = parseInt(contentStyle.lineHeight);
    if (isNaN(lineHeight)) {
      const fontSize = parseInt(contentStyle.fontSize);
      lineHeight = fontSize * 1.4; // Adjust based on your font
    }

    const contentHeight = contentElement.offsetHeight;
    const totalLines = Math.floor(contentHeight / lineHeight);
    lineNumbersElement.innerHTML = Array.from(
      { length: totalLines },
      (_, i) => `${i + 1}<br>`
    ).join("");
  }

  async function copyToClipboard() {
    // Retrieve all text except the one inside <span class="text-muted">
    const textToCopy = Array.from(contentElement.childNodes)
      .filter(
        (node) =>
          !(node.nodeType === 1 && node.classList.contains("text-muted"))
      )
      .map((node) => node.textContent)
      .join("");

    try {
      await navigator.clipboard.writeText(textToCopy);
      // Show copy success message
      showCopyFeedback();
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  }

  let copyButtonElement; // Ensure this is defined at the top of your <script> tag

  function showCopyFeedback() {
    const feedbackElement = document.createElement("div");
    feedbackElement.textContent = "Copied!";
    feedbackElement.style.position = "absolute";
    feedbackElement.style.left = `${
      copyButtonElement.getBoundingClientRect().left
    }px`;
    feedbackElement.style.top = `${
      copyButtonElement.getBoundingClientRect().top - 70
    }px`; // Adjust this value as needed
    feedbackElement.style.color = "#727e8d";
    feedbackElement.style.fontWeight = "bold";
    feedbackElement.style.fontSize = "10px";
    feedbackElement.style.zIndex = "1000"; // Ensure it's above other elements

    document.body.appendChild(feedbackElement);

    // Remove the message after 2 seconds
    setTimeout(() => {
      document.body.removeChild(feedbackElement);
    }, 2000);
  }
</script>

<main>
  <div class="body-parallax">
    <div class="parallax">
      <div class="parallax-group"></div>
      <div class="parallax-group"></div>
      <div class="parallax-group"></div>
      <div class="parallax-group"></div>
      <div class="parallax-group"></div>
    </div>
  </div>
  <section>
    <h1 class="heading">Lorem Ipsum</h1>
    <p class="description">
      <em>Lorem ipsum</em> is een standaard tekst gebruikt in de grafische sector
      en bij het maken van proefontwerpen.
    </p>
  </section>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="copy-container">
    <button
      class="copy-button"
      on:click={copyToClipboard}
      bind:this={copyButtonElement}
    >
      <img src="/img/icon-copy.svg" alt="Icon Copy" class="copy-icon" /></button
    >
  </div>
  <div class="code-container">
    <pre bind:this={lineNumbersElement} class="line-numbers"></pre>
    <pre
      bind:this={contentElement}
      class="content">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ultricies ex massa, sit amet aliquet risus aliquet vitae. Sed sit amet turpis et augue ultrices rhoncus at vel metus. Nunc malesuada felis in libero sodales pulvinar. Donec rutrum est sed luctus sodales. Sed egestas faucibus purus, fermentum porttitor arcu cursus ac. Vivamus fermentum et justo eget cursus. Pellentesque accumsan ultrices placerat. Fusce dapibus ut orci a posuere. Mauris tristique eget orci ut feugiat. Suspendisse eget leo semper, condimentum nisl a, tempus turpis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ultrices, tellus ac bibendum vulputate, enim nulla bibendum massa, ultrices lobortis quam enim eget sem. Cras in metus elit.<br
      /><br
      />Quisque convallis leo metus, at luctus elit pretium mollis. Aliquam quis tellus est. Vestibulum rutrum lorem vel luctus posuere. Aliquam finibus eros sit amet eleifend sodales. Phasellus at viverra nisi. Suspendisse potenti. Sed sem quam, rhoncus dictum urna eu, malesuada pretium libero. Integer quis odio nulla. Fusce luctus vulputate quam id tempor. Etiam eget nisl leo. Morbi fermentum ullamcorper tellus id euismod.<br
      /><br
      />Nam scelerisque ante ut pharetra pretium. In vitae vehicula sem, eget consequat dolor. Vestibulum mollis libero diam, id molestie est ullamcorper sed. Aenean gravida tortor a orci rutrum sodales. Cras mollis iaculis ipsum id sollicitudin. Mauris at elit vitae odio tempor efficitur. Curabitur convallis turpis vitae diam elementum vestibulum.<br
      /><br /><span class="text-muted"
        >Generated 3 paragraphs, 221 words, 1500 bytes of Lorem Ipsum</span
      >
    </pre>
  </div>
  <div class="copyright">
    <a href="https://faktor22.nl" target="_blank">© Faktor 22 | v.0.1</a>
  </div>
</main>

<style>
  :global(body) {
    margin: 0;
    padding: 0;
    background-color: #23272e;
    color: white;
    font-size: 16px;
    font-family: "Consolas", "Courier New", monospace;
  }

  main {
    margin: 50px 0;
  }

  .body-parallax {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
  }

  .parallax {
    position: relative;
    display: flex;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    z-index: 0;
  }

  .parallax-group {
    position: relative;
    display: block;
    height: 100vh;
    width: 40%;
    border-right: 1px solid #3a434d;
  }

  .code-container {
    display: flex;
    max-width: 1000px;
    padding: 0;
    margin: 0 auto;
    background-color: #1e2227;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
  }

  .copy-container {
    display: flex;
    justify-content: end;
    margin-bottom: 5px !important;
  }

  .copy-button {
    cursor: pointer;
    background-color: #1e2227;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    align-content: center;
    display: flex;
  }

  .copy-icon {
    width: 15px;
    filter: invert(49%) sepia(78%) saturate(89%) hue-rotate(173deg)
      brightness(85%) contrast(86%);
  }

  section,
  .copyright,
  .copy-container {
    max-width: 1000px;
    margin: 20px auto;
  }

  .copyright {
    text-align: right;
    font-size: 0.8em;
  }

  .copyright a {
    color: #7b8898;
  }

  .heading {
    color: #7b8898;
    margin: 0 0 10px 0;
  }

  .description {
    color: #fff;
    margin: 0 0 10px 0;
  }

  .heading,
  .description {
    font-size: 2.5em;
    z-index: 1;
    font-weight: 700;
    font-style: normal;
    font-family: "Quarto A", "Quarto B", Georgia, Times, "Times New Roman",
      "Microsoft YaHei New", "Microsoft Yahei", "微软雅黑", 宋体, SimSun,
      STXihei, "华文细黑", serif;
    line-height: 1.125;
    letter-spacing: 0.025em;
  }

  .line-numbers,
  .content {
    margin: 0;
    padding: 20px;
    font-size: 0.85em;
  }

  .line-numbers {
    color: #444b5a;
    text-align: right;
    background-color: #1e2227;
    border-right: 1px solid #23272e;
    width: 20px;
    flex-shrink: 0;
    user-select: none;
    margin-right: 0;
  }

  .text-muted {
    color: #6b6e76;
    user-select: none;
  }

  .content {
    white-space: pre-line;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: #abb2bf;
    background-color: #1e2227;
    line-height: auto;
  }

  @media (max-width: 768px) {
    .parallax-group {
      width: 50%;
    }
    main {
      margin: 25px;
    }
  }
</style>
